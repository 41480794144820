import {useState, useEffect, useCallback, memo} from 'react';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import {
    Box,
    CircularProgress,
    CircularProgressProps,
    Typography,
    useTheme,
} from '@mui/material';
import Slide, {SlideProps} from '@mui/material/Slide';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import FmdBadRoundedIcon from '@mui/icons-material/FmdBadRounded';
import {createSelector} from 'reselect';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {ReactComponent as X} from 'assets/images/x.svg';
import {
    selectToast,
    useAppDispatch,
    useAppSelector,
    selectAuthUserProfileDarkMode,
} from 'store';
import {showToastAction} from 'store/actions/toast';
import styles from './styles.module.scss';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionLeft(props: TransitionProps) {
    return <Slide {...props} direction={'left'} />;
}

function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
}

function TransitionRight(props: TransitionProps) {
    return <Slide {...props} direction="right" />;
}

function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
}

const CircularProgressWithLabel = memo(
    (
        props: CircularProgressProps & {
            value: number;
            appMode: 'light' | 'dark' | 'system' | undefined;
        },
    ) => {
        return (
            <Box
                sx={{
                    position: 'relative',
                    display: 'inline-flex',
                }}>
                <CircularProgress
                    variant="determinate"
                    value={props.value}
                    size={25}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <X
                        className={styles.x}
                        width={10}
                        height={10}
                        fill={
                            props.appMode === 'dark'
                                ? '#cbcbcb'
                                : 'rgba(0, 0, 0, 0.85)'
                        }
                    />
                    {/* <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary">{`${Math.round(
                    props.value / 20,
                )}`}</Typography> */}
                </Box>
            </Box>
        );
    },
);

const stateSelectorHandle = createSelector(
    selectToast,
    selectAuthUserProfileDarkMode,
    (toast, appMode) => {
        return {toast, appMode};
    },
);

export const Toast = () => {
    const stateSelector = useCallback(stateSelectorHandle, []);
    const {toast, appMode} = useAppSelector(stateSelector);
    const dispatch = useAppDispatch();
    const {
        vertical,
        horizontal,
        open,
        title,
        subTitle,
        transitionDirection,
        icon,
        emoji,
        autoHideDuration,
        sessionId,
        showJoinButton,
        publicSessionId,
    } = toast;
    const [progress, setProgress] = useState<number>(0);
    const history = useHistory();
    const {t} = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        if (open && autoHideDuration !== null) {
            const timer = setInterval(() => {
                setProgress(prevProgress =>
                    prevProgress >= 100 ? 0 : prevProgress + 20,
                );
                if (progress === 100) {
                    dispatch(
                        showToastAction({
                            open: false,
                            vertical: 'top',
                            horizontal: 'center',
                            title: '',
                            subTitle: '',
                        }),
                    );
                    setProgress(0);
                    clearInterval(timer);
                }
            }, 1000);
            return () => {
                clearInterval(timer);
            };
        }

        return () => {
            setProgress(0);
        };
    }, [dispatch, open, progress, autoHideDuration]);

    return (
        <Snackbar
            anchorOrigin={{vertical, horizontal}}
            open={open}
            sx={{marginTop: 2.5, zIndex: 999999999}}
            onClose={() =>
                dispatch(
                    showToastAction({
                        ...toast,
                        open: false,
                    }),
                )
            }
            autoHideDuration={
                autoHideDuration === null ? null : autoHideDuration || 5000
            }
            TransitionComponent={
                transitionDirection === 'left'
                    ? TransitionLeft
                    : transitionDirection === 'up'
                    ? TransitionUp
                    : transitionDirection === 'right'
                    ? TransitionRight
                    : TransitionDown
            }
            ContentProps={{
                className: styles.snackBar,
                style: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    overflow: 'hidden',
                    position: 'relative',
                },
            }}
            message={
                <div className={styles.snackBarContent}>
                    <div className={styles.iconBooked}>
                        {emoji ? (
                            emoji
                        ) : icon?.toLowerCase() === 'warn' ? (
                            <WarningRoundedIcon
                                sx={{
                                    color: '#ffcc00',
                                    width: 25,
                                    height: 25,
                                }}
                            />
                        ) : icon?.toLowerCase() === 'error' ? (
                            <FmdBadRoundedIcon
                                sx={{
                                    color: '#cc3300',
                                    width: 25,
                                    height: 25,
                                }}
                            />
                        ) : (
                            <CheckCircleRoundedIcon
                                sx={{
                                    color: '#339900',
                                    width: 25,
                                    height: 25,
                                }}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 5,
                            maxWidth: 300,
                            width: '100%',
                        }}>
                        <Typography
                            sx={{
                                color:
                                    theme.palette.mode === 'light'
                                        ? 'rgba(0, 0, 0, 0.85)'
                                        : '#fff7ea',
                            }}
                            className={styles.snackTitle}>
                            {title}
                        </Typography>
                        <span className={styles.snackSubtitle}>{subTitle}</span>
                        {((sessionId && showJoinButton) || publicSessionId) && (
                            <Box className={styles.actions}>
                                <Typography
                                    onClick={() => {
                                        dispatch(
                                            showToastAction({
                                                ...toast,
                                                open: false,
                                            }),
                                        );

                                        history.push(
                                            publicSessionId
                                                ? `/public/lobby/${publicSessionId}`
                                                : `/focus-session/${sessionId}/lobby`,
                                        );
                                    }}
                                    component={'h1'}
                                    className={styles.joinNow}>
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.0268 10.0102L9.72683 6.88424C9.64953 6.82809 9.55822 6.79436 9.46298 6.78679C9.36773 6.77922 9.27224 6.79808 9.18703 6.8413C9.10182 6.88453 9.0302 6.95044 8.98005 7.03177C8.92991 7.1131 8.90319 7.20669 8.90283 7.30223V13.5522C8.90265 13.6479 8.92901 13.7417 8.97897 13.8233C9.02893 13.9048 9.10054 13.9709 9.18583 14.0142C9.25901 14.0512 9.33986 14.0704 9.42183 14.0702C9.52985 14.0692 9.63479 14.0342 9.72183 13.9702L14.0218 10.8472C14.0885 10.7997 14.1429 10.7369 14.1804 10.6641C14.2179 10.5913 14.2374 10.5106 14.2374 10.4287C14.2374 10.3468 14.2179 10.2661 14.1804 10.1934C14.1429 10.1206 14.0885 10.0578 14.0218 10.0102H14.0268Z"
                                            fill="#FF9A00"
                                        />
                                        <path
                                            d="M10.6118 0.0585938C8.56418 0.0587917 6.5626 0.66611 4.86011 1.80377C3.15762 2.94142 1.83067 4.55833 1.04704 6.45006C0.263407 8.34179 0.0582721 10.4234 0.457572 12.4317C0.856873 14.44 1.84268 16.2848 3.29035 17.7329C4.73802 19.181 6.58256 20.1673 8.59074 20.5672C10.5989 20.9671 12.6806 20.7626 14.5726 19.9795C16.4645 19.1964 18.0818 17.8699 19.22 16.1678C20.3581 14.4656 20.966 12.4642 20.9668 10.4166C20.9673 9.05647 20.6999 7.70956 20.1797 6.45283C19.6595 5.1961 18.8969 4.05417 17.9352 3.09227C16.9736 2.13038 15.8319 1.36737 14.5753 0.846841C13.3187 0.326312 11.9719 0.0584623 10.6118 0.0585938ZM10.6118 19.0386C8.90556 19.039 7.23754 18.5334 5.81869 17.5857C4.39985 16.638 3.29392 15.2908 2.64079 13.7145C1.98766 12.1383 1.81667 10.4037 2.14944 8.73024C2.48221 7.05677 3.3038 5.51958 4.51029 4.31309C5.71678 3.1066 7.25397 2.28501 8.92743 1.95224C10.6009 1.61947 12.3355 1.79046 13.9117 2.44359C15.488 3.09672 16.8352 4.20265 17.7829 5.62149C18.7306 7.04034 19.2362 8.70836 19.2358 10.4146C19.2359 11.5472 19.013 12.6688 18.5796 13.7153C18.1463 14.7618 17.5111 15.7127 16.7103 16.5137C15.9095 17.3147 14.9587 17.9501 13.9124 18.3837C12.866 18.8172 11.7444 19.0405 10.6118 19.0406V19.0386Z"
                                            fill="#FF9A00"
                                        />
                                    </svg>
                                    {t('call.participateNow')}
                                </Typography>
                            </Box>
                        )}
                    </div>
                    <div
                        onClick={() => {
                            dispatch(
                                showToastAction({
                                    ...toast,
                                    open: false,
                                }),
                            );
                        }}
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            cursor: 'pointer',
                            pointerEvents: 'all',
                            zIndex: 111,
                        }}>
                        <CircularProgressWithLabel
                            value={progress}
                            appMode={appMode}
                        />
                    </div>
                </div>
            }
            key={vertical + horizontal}
        />
    );
};
