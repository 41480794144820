import {FC, memo, ReactElement, SetStateAction, Dispatch} from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface IDropDownComponentProps {
    startAdornment: ReactElement<any, any>;
    IconComponent: ReactElement<any, any>;
    matches: boolean;
    labelClassName?: string;
    inputPropsClassName?: string;
    menuItems: any[];
    label: string;
    emLabel: string;
    displayEmpty: boolean;
    labelId: string;
    value: string | undefined;
    sx: Record<string, unknown>;
    setOptions: Dispatch<
        SetStateAction<{
            videoInput?: string;
            sendResolution?: string;
            receiveResolution?: string;
            audioInput?: string;
            speakers?: string;
            noiseSuppression?: string;
        }>
    >;
    customKey: string;
    disabled?: boolean;
    disabledOptions?: string[];
}

export const DropDownComponent: FC<IDropDownComponentProps> = memo(
    ({
        startAdornment,
        IconComponent,
        matches,
        labelClassName,
        inputPropsClassName,
        menuItems,
        label,
        emLabel,
        displayEmpty,
        labelId,
        value,
        sx,
        setOptions,
        customKey,
        disabled = false,
        disabledOptions,
    }) => {
        return (
            <>
                <InputLabel id={labelId} className={labelClassName}>
                    {label}
                </InputLabel>
                <Select
                    disabled={!!disabled}
                    variant="standard"
                    labelId={labelId}
                    displayEmpty={displayEmpty}
                    sx={sx}
                    inputProps={{
                        className: inputPropsClassName,
                    }}
                    onChange={e => {
                        setOptions(
                            (prev: {
                                videoInput?: string;
                                sendResolution?: string;
                                receiveResolution?: string;
                                audioInput?: string;
                                speakers?: string;
                                noiseSuppression?: string;
                            }) => ({
                                ...prev,
                                [customKey]: e.target.value,
                            }),
                        );
                    }}
                    IconComponent={() => IconComponent}
                    value={value}
                    startAdornment={startAdornment}>
                    <MenuItem
                        disabled
                        value=""
                        sx={{
                            display: 'none',
                            backgroundColor: '#fff',
                            fontFamily: 'Brandon Grotesque',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: 20,
                            color: 'var(--theme-label-color)',
                            '&:hover': {
                                backgroundColor: 'primary.main',
                                color: '#fff',
                            },
                        }}>
                        <em>{emLabel}</em>
                    </MenuItem>
                    {menuItems.map(
                        (
                            menuItem: {label: string; value: string},
                            index: number,
                        ) => (
                            <MenuItem
                                disabled={Boolean(
                                    disabledOptions?.length &&
                                        disabledOptions?.indexOf(
                                            menuItem.value,
                                        ) !== -1,
                                )}
                                key={`menu-item-${menuItem.value}-${menuItem.label}-${index}`}
                                value={menuItem.value}>
                                {menuItem.label}
                            </MenuItem>
                        ),
                    )}
                </Select>
            </>
        );
    },
);

DropDownComponent.displayName = 'DropDownComponent';
