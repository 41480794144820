import {useCallback, useEffect, useState} from 'react';
import {createSelector} from 'reselect';
import useWebSocket, {ReadyState} from 'react-use-websocket';

import {
    selectWSReconnectInterval,
    useAppDispatch,
    useAppSelector,
    setWSReconnectIntervalAction,
    selectWSReconnectAttempts,
    setWSReconnectAttemptsAction,
    selectAuthUserFullName,
} from 'store';

const stateSelectorHandle = createSelector(
    selectWSReconnectInterval,
    selectWSReconnectAttempts,
    selectAuthUserFullName,
    (wsReconnectInterval, wsReconnectAttempts, authUserFullName) => {
        return {
            wsReconnectInterval,
            wsReconnectAttempts,
            authUserFullName,
        };
    },
);

export const useWs = () => {
    const [socketUrl, setSocketUrl] = useState<string>(
        `${process.env.REACT_APP_WS_INITIAL_CONNECTION}`,
    );
    const dispatch = useAppDispatch();
    const stateSelector = useCallback(stateSelectorHandle, []);
    const {wsReconnectInterval, wsReconnectAttempts, authUserFullName} =
        useAppSelector(stateSelector);
    const [messageHistory, setMessageHistory] = useState<any[]>([]);
    const [reconnectAttempts, setReconnectAttempts] = useState<number>(0);

    const onConnectionClose = useCallback(() => {
        if (reconnectAttempts) {
            dispatch(
                setWSReconnectIntervalAction({
                    interval: wsReconnectInterval * 2,
                }),
            );
        }
        setReconnectAttempts(prev => prev + 1);
    }, [dispatch, reconnectAttempts, wsReconnectInterval]);

    const asyncUrl: () => Promise<string> = useCallback(() => {
        return new Promise(resolve => {
            resolve(socketUrl);
        });
    }, [socketUrl]);

    const {lastMessage, readyState, sendJsonMessage} = useWebSocket(asyncUrl, {
        onError: (e: WebSocketEventMap['error']) => {
            console.log('WEBSOCKET ERROR', {e});
        },
        onClose: (e: WebSocketEventMap['close']) => {
            console.log('WEBSOCKET CLOSED', {e});
            onConnectionClose();
        },
        onOpen: (e: WebSocketEventMap['open']) =>
            console.log('WEBSOCKET CONNECTION ESTABLISHED', {e}),
        // ** Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: closeEvent => wsReconnectAttempts < 10,
        reconnectInterval: wsReconnectInterval,
        reconnectAttempts: 10,
        onMessage: (event: WebSocketEventMap['message']) => {
            // console.log({event});
        },
        retryOnError: true,
        onReconnectStop: (numAttempts: number) => {
            console.log('Reconnect stopped: ' + numAttempts);
        },
        // share: true,
    });

    const connectionStatus: string = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    const handleClickSendMessage = useCallback(
        (message: any) => {
            sendJsonMessage(message);
        },
        [sendJsonMessage],
    );

    useEffect(() => {
        if (lastMessage !== null) {
            setMessageHistory((prev: any) =>
                prev.concat(lastMessage ? JSON.parse(lastMessage.data) : []),
            );
        }
    }, [lastMessage, setMessageHistory]);

    useEffect(() => {
        if (reconnectAttempts) {
            try {
                dispatch(
                    setWSReconnectAttemptsAction({number: reconnectAttempts}),
                );
            } catch (error) {
                console.log(
                    'An error happened while incrementing the reconnect attempts.',
                );
            }
        }
    }, [dispatch, reconnectAttempts]);

    useEffect(() => {
        return () => {
            setReconnectAttempts(0);
            setSocketUrl(`${process.env.REACT_APP_WS_INITIAL_CONNECTION}`);
            setMessageHistory([]);
        };
    }, []);

    return {
        messageHistory,
        setMessageHistory,
        connectionStatus,
        handleClickSendMessage,
        setSocketUrl,
        lastMessage,
        readyState,
        authUserFullName,
    };
};
